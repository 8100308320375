export default (props) => {
  const width = props.width ? props.width : 16
  const color = props.color ? props.color : '#ADFFE1'
  const className = props.className ? props.className : ''
  const style = {
    ...props.style
  }

  return (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 10" 
        fill={color}
        width={width}
        style={style}
        className={`icon phone solid ${className}`}
    >
    <path d="M9.54342 7.25568C9.12871 6.82955 7.94988 6.03125 
        7.17726 6.03125C6.9983 6.03125 6.84207 6.07102 6.71425 
        6.15341C6.33646 6.39489 6.03536 6.58239 5.8905 
        6.58239C5.81096 6.58239 5.72574 6.51136 5.53827 
        6.34943L5.50702 6.32102C4.98721 5.86932 4.87642 
        5.75284 4.67475 5.54261L4.62362 5.48864C4.58669 5.4517 
        4.55544 5.41761 4.5242 5.38636C4.34808 5.20455 4.22026 
        5.07386 3.76862 4.5625L3.74873 4.53977C3.53285 4.29545 
        3.39082 4.13636 3.3823 4.01989C3.37378 3.90625 3.4732 
        3.72159 3.72601 3.37784C4.03279 2.96307 4.04415 2.4517 
        3.76293 1.85795C3.53853 1.3892 3.1721 0.940341 2.84828 
        0.545455L2.81988 0.511364C2.5415 0.170455 2.21768 0 
        1.85694 0C1.45642 0 1.12408 0.215909 0.947965 
        0.329545C0.933762 0.338068 0.919559 0.349432 0.905357 
        0.357955C0.510522 0.607955 0.223628 0.951705 0.115688 
        1.30114C-0.0462227 1.8267 -0.154163 2.50852 0.621303 
        3.92614C1.29167 5.15341 1.89954 5.97727 2.86533 
        6.96875C3.7743 7.90057 4.17765 8.2017 5.08094 
        8.85511C6.08649 9.58239 7.05227 10 7.72832 10C8.35608 10 
        8.85033 10 9.55479 9.15057C10.2933 8.25852 9.98655 
        7.71307 9.54342 7.25568Z" />
    </svg>
  )
}