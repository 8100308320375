const statusOptions = JSON.parse(`[
    "busy",
    "at work",
    "Mixed feelings",
    "Sunny vibes",
    "Smiling",
    "Glowing",
    "Thrilled",
    "Ecstatic",
    "Let’s Party",
    "Overjoyed",
    "Blissful",
    "Feeling lucky",
    "Carefree",
    "Enigmatic",
    "straightforward",
    "Cutie",
    "Enjoying-Loneliness",
    "Happy Weekends",
    "Monday-Morning",
    "Simply- happy",
    "Rich-bitch",
    "OverThinker",
    "Enthusiastic",
    "Refreshing Air",
    "Inspired",
    "Lonely",
    "Open-minded",
    "Hungry",
    "single",
    "Heartbroken"
]`)





const usersStatus = JSON.parse(`[
    {
        "userName": "john joe",
        "thumbnail": "/image/status/4-2.png"
    },
    {
        "userName": "pearl joe",
        "thumbnail": "/image/status/3-2.png"
    },
    {
        "userName": "john joe",
        "thumbnail": "/image/status/8.png"
    },
    {
        "userName": "mike joe",
        "thumbnail": "/image/status/5-2.png"
    },
    {
        "userName": "jane joe",
        "thumbnail": "/image/status/2-2.png"
    },
    {
        "userName": "joy joe",
        "thumbnail": "/image/status/6.png"
    },
    {
        "userName": "bella joe",
        "thumbnail": "/image/status/7.png"
    },
    {
        "userName": "chris joe",
        "thumbnail": "/image/status/9.png"
    },
    {
        "userName": "emily joe",
        "thumbnail": "/image/status/10.png"
    }
]`)

export { 
    statusOptions,
    usersStatus
}