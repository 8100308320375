export default (props) => {
  const width = props.width ? props.width : 180
  const color = props.color ? props.color : '#fff'
  const className = props.className ? props.className : ''
  const style = {
    height: 'auto',
    transform: 'rotateY(180deg)',
    ...props.style
  }

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      version="1.1" 
      viewBox="0 0 100 100" 
      fill={color} 
      width={width}
      style={style}
      className={`icon noWifi ${className}`}
    >
      <path 
        d="M48.29,47.941c-2.01,0.541-3.91,1.588-5.484,
          3.162c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,
          0.451,0.293,0.707,0.293  s0.512-0.098,0.707-0.293c0.751-0.752,
          1.591-1.361,2.485-1.832L48.29,47.941z M49.481,58.318l1.343,1.447 
          c0.374,0.404,0.984,0.404,1.358,0l1.345-1.447C52.409,57.199,
          50.599,57.199,49.481,58.318z M51.412,42.533 
          c-4.784,0.049-9.269,1.953-12.625,5.369c-0.388,0.395-0.382,
          1.027,0.013,1.414s1.026,0.381,1.414-0.012 c2.771-2.818,
          6.339-4.377,9.998-4.693L51.412,42.533z M54.143,47.803l-1.061,
          1.838c2.09,0.32,4.1,1.271,5.705,2.877 c0.391,0.391,1.022,0.391,
          1.414,0c0.391-0.391,0.391-1.023,0-1.414C58.478,49.381,56.364,
          48.287,54.143,47.803z M51.426,52.508 l-1.28,2.219c1.592-0.484,
          3.39-0.119,4.646,1.137c0.195,0.195,0.451,0.293,0.707,
          0.293s0.512-0.098,0.707-0.293 c0.391-0.391,0.391-1.023,
          0-1.414C54.889,53.133,53.154,52.488,51.426,52.508z M64.171,
          47.684c-2.116-2.08-4.656-3.57-7.421-4.396  l-1.042,1.805c2.581,
          0.686,5.025,2.018,7.062,4.02c0.197,0.193,0.454,0.289,0.71,
          0.287c0.257-0.002,0.512-0.104,0.704-0.299  C64.571,48.703,
          64.565,48.07,64.171,47.684z M56.01,
          39.639c-0.477-0.281-1.089-0.121-1.369,0.354l-10,17 
          c-0.278,0.477-0.121,1.09,0.355,1.369c0.159,0.094,0.334,
          0.139,0.506,0.139c0.343,0,0.677-0.176,0.863-0.492l10-17 
          C56.645,40.531,56.486,39.918,56.01,39.639z"
        style={{ 
          scale: '350%', 
          transform: 'translate(-37%, -36%)' 
        }}
          />
    </svg>
  )
}