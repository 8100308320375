export default (props) => {
  const width = props.width ? props.width : 8
  const color = props.color ? props.color : '#fff'
  const className = props.className ? props.className : ''
  const style = {
    ...props.style
  }

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4 13" 
      width={width}
      style={style}
      className={`icon menulist ${className}`}
      fill={color} 
    >
      <path d="M0.879999 1.72117C0.879999 1.3745 0.999999 1.08117 1.24 0.841171C1.48 
        0.601171 1.77333 0.481171 2.12 0.481171C2.46667 0.481171 2.76 0.601171 3 
        0.841171C3.24 1.08117 3.36 1.3745 3.36 1.72117C3.36 2.0545 3.24 2.3345 3 
        2.56117C2.76 2.80117 2.46667 2.92117 2.12 2.92117C1.77333 2.92117 1.48 
        2.80117 1.24 2.56117C0.999999 2.3345 0.879999 2.0545 0.879999 
        1.72117ZM0.879999 6.52039C0.879999 6.17372 0.999999 5.88039 1.24 
        5.64039C1.48 5.40039 1.77333 5.28039 2.12 5.28039C2.46667 5.28039 2.76 
        5.40039 3 5.64039C3.24 5.88039 3.36 6.17372 3.36 6.52039C3.36 6.85372 
        3.24 7.13372 3 7.36039C2.76 7.60039 2.46667 7.72039 2.12 7.72039C1.77333 
        7.72039 1.48 7.60039 1.24 7.36039C0.999999 7.13372 0.879999 6.85372 
        0.879999 6.52039ZM0.879999 11.3196C0.879999 10.9729 0.999999 10.6796 
        1.24 10.4396C1.48 10.1996 1.77333 10.0796 2.12 10.0796C2.46667 10.0796 
        2.76 10.1996 3 10.4396C3.24 10.6796 3.36 10.9729 3.36 11.3196C3.36 
        11.6529 3.24 11.9329 3 12.1596C2.76 12.3996 2.46667 12.5196 2.12 
        12.5196C1.77333 12.5196 1.48 12.3996 1.24 12.1596C0.999999 11.9329 
        0.879999 11.6529 0.879999 11.3196Z" 
        // fill="white"
        />
    </svg>
  )
}