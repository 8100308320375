export default (props) => {
  const width = props.width ? props.width : 16
  const color = props.color ? props.color : '#ADFFE1'
  const className = props.className ? props.className : ''
  const style = {
    ...props.style
  }

  return (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11 9" 
        fill={color}
        // strokeLinecap="round" 
        // strokeLinejoin="round"
        // strokeWidth="2" 
        width={width}
        style={style}
        className={`icon camera solid ${className}`}
    >
      <path d="M9.89904 1.80737H8.28749L7.32294 0.833276C7.32294 
          0.833276 7.31817 0.828501 7.31578 0.828501L7.31101 
          0.823726C7.16776 0.680477 6.97437 0.589752 6.75472 
          0.589752H4.74923C4.51526 0.589752 4.30755 0.690027 
          4.16191 0.849989V0.852376L3.21885 
          1.80737H1.57148C1.12741 1.80737 0.769287 2.15594 
          0.769287 2.60002V7.42513C0.769287 7.86921 1.12741 
          8.22972 1.57148 8.22972H9.89904C10.3407 8.22972 
          10.7012 7.86921 10.7012 7.42513V2.60002C10.7012 
          2.15594 10.3407 1.80737 9.89904 1.80737ZM5.73526 
          7.02403C4.52003 7.02403 3.52922 6.03084 3.52922 
          4.81322C3.52922 3.59321 4.52003 2.60241 5.73526 
          2.60241C6.95288 2.60241 7.9413 3.59321 7.9413 
          4.81322C7.9413 6.03084 6.95288 7.02403 5.73526 
          7.02403ZM9.74863 3.08468C9.5648 3.08468 9.41438 2.93427 
          9.41438 2.74804C9.41438 2.56182 9.5648 2.41141 9.74863 
          2.41141C9.93247 2.41141 10.0829 2.56182 10.0829 
          2.74804C10.0829 2.93427 9.93247 3.08468 9.74863 
          3.08468Z" />
      <path d="M5.73532 3.14198C4.81374 3.14198 4.06885 3.88926 
          4.06885 4.81322C4.06885 5.73479 4.81374 6.48446 5.73532 
          6.48446C6.6545 6.48446 7.40178 5.73718 7.40178 
          4.81322C7.40178 3.88926 6.6545 3.14198 5.73532 
          3.14198Z" />
    </svg>
  )
}