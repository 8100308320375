import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"



const Root = styled(Box)(() => ({
  width: '100%',
  height: '100vh'
}))



export { Root }